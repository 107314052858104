import React, { Suspense } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from '/src/components/layout/checkout'
import { CartForm } from '/src/components/commerce/form'
import { Loading } from '/src/components/loading'

const CartPage = () => {
  return (
    <Layout>
      <Row className="mt-5">
        <Col xs={12}>
          <Suspense fallback={<Loading />}>
            <CartForm />
          </Suspense>
        </Col>
      </Row>
    </Layout>
  )
}

export default CartPage

export const Head = () => {
  return (
    <>
      <title>Cart | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}
